<template>
  <div class="packagePage" v-if="user">
    <div class="packages-page">
      <div class="listPackages">
        <div class="boxPackage" v-for="item in packages" :key="item.id"
          :class="{ active: item.id == user.stripe_package }">
          <div class="name">{{ item.name }}</div>
          <div class="price">${{ item.price }}</div>
          <div class="features">
            <div class="feature-item">
              <p>SMS Credits: <b>{{ item.sms_credit }}</b></p>
            </div>
            <div class="feature-item">
              <p>Call Credits: <b>{{ item.voice_credit }}</b></p>
            </div>
          </div>
          <button class="btn btn-success btnPurchase mt-4" @click="onPurchasePackage(item)"
            :disabled="item.id == user.stripe_package || processing && selectedPackage && selectedPackage.id == item.id">
            <atom-spinner v-if="processing && selectedPackage && selectedPackage.id == item.id" slot="loading"
              :animation-duration="1500" :size="20" color="#fff" />
            <span v-else>
              Purchase
            </span>
          </button>
        </div>
      </div>
      <div class="listEmpty" v-if="!loading && !packages.length">
        Don't have any packages
      </div>
      <div v-if="loading" class="loadingBox">
        <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="#3578c6" />
      </div>
    </div>
    <vuestic-modal :isOpen="isOpenModalConfirm" @ok="purchasePackage" @cancel="isOpenModalConfirm = false"
      okText="Purchase" cancelText="Cancel" :closeOnOk="false" :processing="processing" okClass="btn btn-primary">
      <span slot="title" class="text-primary">Purchase Package</span>
      <div>Are you sure you want to purchase this package <b v-if="selectedPackage">{{ selectedPackage.name }}</b>?
      </div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalAlert" :cancelShown="false" :focus="true" @close="okModalAlert"
      @ok="okModalAlert" okText="Got It !">
      <span slot="title">Confirmation</span>
      <br />To purchase packages, you will need to add your payment method first.
      <br />
      <br /><b>Please go to <router-link class="link" :to="{ name: 'business.paymentMethods' }">Payment Methods</router-link> page and add
        your credit card.</b>
      <br />
      <br />
      <p><i>Please contact <a class="link" :href="`mailto:${agencyEmail}`">{{ agencyEmail }}</a> if you have further
          questions.</i></p>
    </vuestic-modal>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      loading: false,
      processing: false,
      isOpenModalConfirm: false,
      isOpenModalAlert: false,
      packages: [],
      selectedPackage: null,
    }
  },

  components: {
  },

  mounted() {
    this.getPackages();
  },

  methods: {
    getPackages() {
      this.loading = true
      this.$store.dispatch('business/getPackages')
        .then(({ data }) => {
          this.packages = data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onPurchasePackage(item) {
      if (!this.hasPaymentMethod)
      {
        this.isOpenModalAlert = true;
        return;
      }
      this.selectedPackage = item
      this.isOpenModalConfirm = true
    },

    okModalAlert() {
      this.$router.push({ name: 'business.paymentMethods' })
    },

    purchasePackage() {
      if (this.selectedPackage) {
        this.processing = true
        let param = {
          id: this.selectedPackage.id
        }
        this.$store.dispatch('business/purchasePackage', param)
          .then((res) => {
            this.processing = false
            this.isOpenModalConfirm = false
          })
          .catch(() => {
            this.processing = false
          })
      }
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    hasPaymentMethod() {
      return this.user && this.user.has_payment_method
    },

    agencyEmail() {
      return (this.user && this.user.agency && this.user.agency.email) || "";
    },

    business() {
      return this.user && this.user.business
    },
  },
}
</script>
  
<style scoped lang="scss">
.packagePage {
  text-align: center;
  padding: 20px 20px;

  h2 {
    font-size: 24px;
    text-align: center;
    max-width: 90%;
    line-height: 35px;
    color: #5f666d;
    margin: auto;
    padding-bottom: 30px;

    span {
      font-size: 30px
    }
  }
}

.listPackages .boxPackage {
  display: inline-block;
  margin-right: 2rem;
  padding: 3rem 10px;
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
  transition: transform 250ms ease;
  background: #FFF;
  text-align: center;
  vertical-align: top;
  width: 260px;
  margin-bottom: 30px;
}

.listPackages .boxPackage .name {
  color: #00629f;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
}

.listPackages .boxPackage .price {
  color: #009ff2;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 0px 10px 0px;

  &.price-small {
    font-size: 18px;
  }
}

.listPackages .boxPackage .btnPurchase {
  color: #FFF;
  background-color: #f9bd39;
  border-color: #f9bd39;
  font-size: 14px;
  text-transform: none;
}

.listPackages .boxPackage.active .btnPurchase {
  background-color: #16d409;
  border-color: #16d409;
}

.listPackages .boxPackage:hover {
  transform: translateY(-2px);
}

.listPackages .boxPackage .features {
  margin-top: 20px;
}

.listPackages .boxPackage .features p {
  color: #212529;
  font-size: 15px;
  margin-bottom: 5px;
}

.packagePage .listEmpty {
  text-align: center;
  font-size: 30px;
  color: #666;
  margin-top: 30px;
}

.atom-spinner {
  margin: 0 auto;
}

.loadingBox .atom-spinner {
  margin-top: 100px;
}
</style>
  